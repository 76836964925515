export default {
  urlLoginCheckAPI:
    process.env.REACT_APP_LARAVEL_API_URL + "api/helper/login_check",
  userMe: process.env.REACT_APP_LARAVEL_API_URL + "api/user/me",
  services: {
    list: process.env.REACT_APP_LARAVEL_API_URL + "api/helper/services/list",
    serviceData:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/multihelpers-job/",
    helperAssigned:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/helper-assigned/",
    update:
      process.env.REACT_APP_LARAVEL_API_URL + "api/helper/services/update",
    delayNextContactDate:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/delay-next-contact-date",
    pendingDocumentation:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/pending-documentation/",
    document:
      process.env.REACT_APP_LARAVEL_API_URL + "api/helper/services/documents",
    checkInvoiceNumber:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/check-invoice-number/",
    duplicate:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/multihelpersjob/duplicate-by-code/",
    cantFindMalfunctionOrCantRepair:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/cant-find-malfunction-or-cant-repair",
    assigned:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/assigned-list",
    changeStateAssigned:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/change-state-assigned-task/",
    avaibleAndVisitList:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/avaible-and-visit-list",
    avaibleVisitList:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/avaible-visit-list",
    avaibleServicesList:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/avaible-services-list",
    finished_list:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/finished-list",
    rejectService:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/reject-multihelpers-job",
    RejectReasons:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/helper-reject-multihelpers-job-reason/list",
    invoice_list:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/helper/services/get-helper-invoice",
  },
  visits: {
    list: process.env.REACT_APP_LARAVEL_API_URL + "api/helper/visits/list",
  },
  general: {
    cp: process.env.REACT_APP_LARAVEL_API_URL + "api/postal-code/",
    ExtraUserData:
      process.env.REACT_APP_LARAVEL_API_URL + "api/extra-user-data",
    budget: process.env.REACT_APP_LARAVEL_API_URL + "api/budget",
    binary: process.env.REACT_APP_LARAVEL_API_URL + "api/binary",
  },
  zenvia: {
    send: process.env.REACT_APP_LARAVEL_API_URL + "api/zenvia/template/send",
  },
  mail: {
    helperChangeExecutionDatePost:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/mail/helper-change-execution-date-post",
    dateSetBricoAdministrationReminder:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/mail/date-set-brico-administration-reminder",
  },
  budget: {
    create: process.env.REACT_APP_LARAVEL_API_URL + "api/helper/budget/create",
    postProcess:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/budget/post-process-create-budget",
  },
  publicReminders: {
    helperSendResolutionDataToHelper:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/public-reminders/helper-send-resolution-data-to-helper",
    sendResolutionDataToAdvertiser:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/public-reminders/client-send-resolution-data-to-advertiser",
    sendResolutionDataToAdvertiserBySirena:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/public-reminders/send-resolution-data-to-client/",
    sendVisitData:
      process.env.REACT_APP_LARAVEL_API_URL +
      "api/public-reminders/send-visit-data/",
  },
  taskLogHelper: {
    list:
      process.env.REACT_APP_LARAVEL_API_URL + "api/multihelpersjob/log/helper",
    create:
      process.env.REACT_APP_LARAVEL_API_URL + "api/multihelpersjob/log/helper",
  },
  nextActionCached:
    process.env.REACT_APP_LARAVEL_API_URL + "api/cached/next-action",
};
